import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PromoPopup.css';

const PromoPopup = ({ onClose }) => {
  const [groupedData, setGroupedData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://apptvendors.in/api/service-users', {
          headers: {
            'x-auth-token': token,
          },
        });

        const fetchedData = response.data;
        groupDataByService(fetchedData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        // Handle error (e.g., show error message to user)
      }
    };

    fetchData();
  }, []);

  const groupDataByService = (fetchedData) => {
    const grouped = fetchedData.reduce((acc, entry) => {
      const serviceName = entry.Service.name;
      const vendorName = entry.Agency?.name || 'NA';
      const key = `${serviceName}-${vendorName}`;

      if (!acc[key]) {
        acc[key] = {
          name: serviceName,
          vendor: vendorName,
          userCount: 0
        };
      }
      acc[key].userCount += 1;
      return acc;
    }, {});

    setGroupedData(grouped);
  };

  return (
    <div className="promo-popup">
      <div className="promo-popup-content">
        <h3>Number of users interested in vendor service</h3>
        <table className="promo-table">
          <thead>
            <tr>
              <th>Service</th>
              <th>Vendors</th>
              <th>Interested Users</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(groupedData).map((service, index) => (
              <tr key={index}>
                <td data-label="Service">{service.name}</td>
                <td data-label="Vendor">{service.vendor}</td>
                <td data-label="User Count">{service.userCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="close-button" onClick={onClose}>&times;</button>
      </div>
    </div>
  );
};

export default PromoPopup;