import React, { useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [services, setServices] = useState([]);
  const isAuthenticated = !!localStorage.getItem('token');
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.setItem('manualLogout', 'true');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    sessionStorage.removeItem('promoPopupShown'); 
    navigate('/');
    window.location.reload();
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    // Fetch the list of services (mocked or from API)
    const fetchServices = async () => {
      try {
        const response = await fetch('https://apptvendors.in/api/services', {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        });
        const data = await response.json();
        setServices(data);
      } catch (error) {
        console.error('Failed to fetch services:', error);
      }
    };

    if (isAuthenticated) {
      fetchServices();
    }
  }, [isAuthenticated]);

  // Handle service selection and navigate to ReviewPage
  const handleServiceSelect = (serviceId) => {
    navigate(`/service/${serviceId}`); // Navigate to the ReviewPage for the selected service
  };

  return (
    <nav className="navbar">
      <h1>Apartment Services</h1>
      <div className="navbar-menu-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <Link to="/">Home</Link>
        {isAuthenticated ? (
          <>
            <Link to="/services">Services</Link>
            <div className="dropdown">
              <button className="dropdown-button">Reviews</button>
              <div className="dropdown-content">
                {services.map((service) => (
                  <button 
                    key={service.id} 
                    className="dropdown-item"
                    onClick={() => handleServiceSelect(service.id)}
                  >
                    {service.name}
                  </button>
                ))}
              </div>
            </div>
            <Link to="/members">Members</Link>
            <button onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <>
            <Link to="/login">Login</Link>
            <Link to="/signup">Sign Up</Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
