
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ReviewPage.css';
import { useParams } from 'react-router-dom';
import StarRating from './StarRating'; // Import the StarRating component
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';

const ReviewPage = () => {
  const { serviceId } = useParams();
  const [serviceName, setServiceName] = useState('');
  const [agencies, setAgencies] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [newReview, setNewReview] = useState('');
  const [rating, setRating] = useState(0);

  // Fetch service name by serviceId
  useEffect(() => {
    const fetchServiceName = async () => {
      try {
        const response = await axios.get(`https://apptvendors.in/api/services/${serviceId}`);
        setServiceName(response.data.name);
      } catch (error) {
        console.error('Failed to fetch service name:', error);
      }
    };

    fetchServiceName();
  }, [serviceId]);

  // Fetch agencies (vendors) for the selected service
  useEffect(() => {
    const fetchAgencies = async () => {
      try {
        const response = await axios.get(`https://apptvendors.in/api/agencies?serviceId=${serviceId}`, {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });
        setAgencies(response.data);
        setSelectedAgency(response.data[0]); // Automatically select the first agency
      } catch (error) {
        console.error('Failed to fetch agencies:', error);
      }
    };

    fetchAgencies();
  }, [serviceId]);

  // Fetch reviews for the selected agency
  useEffect(() => {
    if (selectedAgency) {
      const fetchReviews = async () => {
        try {
          const response = await axios.get(`https://apptvendors.in/api/reviews?vendorId=${selectedAgency.id}&serviceId=${serviceId}`, {
            headers: { 'x-auth-token': localStorage.getItem('token') },
          });
          
          if (response.status === 200) {
            setReviews(response.data);
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            setReviews([]);
          } else {
            console.error('Failed to fetch reviews:', error);
          }
        }
      };
  
      fetchReviews();
    }
  }, [selectedAgency, serviceId]);

  // Calculate the overall rating
  const calculateOverallRating = (agency) => {
    if (!reviews.length) return 0; // Return 0 if there are no reviews
    const agencyReviews = reviews.filter(review => review.agencyId === agency.id);
    const totalRating = agencyReviews.reduce((acc, review) => acc + review.rating, 0);
    return agencyReviews.length ? totalRating / agencyReviews.length : 0;
  };

  // Handle agency card click
  const handleAgencyChange = (agency) => {
    setSelectedAgency(agency);
  };

  // Handle review submission
  const handleSubmitReview = async () => {
    if (!selectedAgency) {
      alert('Please select an agency first.');
      return;
    }

    if (!rating || !newReview.trim()) {
      alert('Please provide a rating and review.');
      return;
    }

    try {
      await axios.post('https://apptvendors.in/api/reviews', {
        vendorId: selectedAgency.id,
        serviceId: serviceId,
        feedback: newReview,
        rating: rating,
      }, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });

      alert('Review submitted successfully!');
      setNewReview('');
      setRating(0);

      // Refresh the reviews after submission
      const response = await axios.get(`https://apptvendors.in/api/reviews?vendorId=${selectedAgency.id}&serviceId=${serviceId}`, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });
      setReviews(response.data);
    } catch (error) {
      console.error('Failed to submit review:', error);
      alert('Failed to submit review.');
    }
  };

  return (
    <div>
      <Navbar />
      <div className="review-page">
        <h1>{serviceName}</h1>
        <div className="agency-cards">
          {agencies.map((agency) => (
            <div
              key={agency.id}
              className={`agency-card ${agency.id === selectedAgency?.id ? 'selected' : ''}`}
              onClick={() => handleAgencyChange(agency)}
            >
              <h3>{agency.name}</h3>
              {/* Display overall rating of the agency (non-interactive) */}
              <p>Overall Rating: <StarRating rating={calculateOverallRating(agency)} interactive={false} /></p>
            </div>
          ))}
        </div>

        {selectedAgency && (
          <div className="reviews-section">
            <h2>Reviews for {selectedAgency.name}</h2>
            {reviews.length > 0 ? (
              reviews.map((review) => (
                <div key={review.id} className="review-item">
                  <p><strong>{review.User?.username || 'Anonymous'}</strong></p>
                  <p>{review.review}</p>
                  <p>Rating: <StarRating rating={review.rating} interactive={false} /></p>
                </div>
              ))
            ) : (
              <p>No reviews yet.</p>
            )}

            <div className="review-form">
              <h3>Write a Review for {selectedAgency.name}</h3>
              <textarea
                value={newReview}
                onChange={(e) => setNewReview(e.target.value)}
                placeholder="Write your review here..."
              />
              <StarRating rating={rating} setRating={setRating} interactive />
              <button onClick={handleSubmitReview}>Submit Review</button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ReviewPage;
