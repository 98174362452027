import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ServiceSelection from './pages/ServiceSelection';
import ServiceDetail from './pages/ServiceDetail';
import ServiceCard from './Component/ServiceCard';
import AgencyDetail from './Component/AgencyDetail'; 
import MembersPage from './pages/MembersPage';
import SessionMonitor from './Component/SessionMonitor'; // Import the SessionMonitor
import ProtectedRoute from './Component/ProtectedRoute';
import ServiceUsersTable from './pages/ServiceUsersTable';
import ReviewPage from './pages/ReviewPage'
import ForgotPassword from './pages/ForgotPassword'
import ResetPassword from './pages/ResetPassword';
import VendorLogin from './vendor/VendorLogin';
import VendorHomepage from './vendor/VendorHomepage';

const App = () => {
  return (
    <Router>
      {/* Include SessionMonitor to monitor the token expiration */}
      <SessionMonitor />

      {/* Define all routes within Routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/services" element={<ProtectedRoute element={ServiceSelection} />} />
        <Route path="/service-users" element={<ServiceUsersTable />}/>
        <Route path="/services/:id" element={<ProtectedRoute element={ServiceDetail} />} />
        <Route path="/service/:serviceId" element={<ReviewPage />} /> 
        <Route path="/agencies/:id" element={<ProtectedRoute element={AgencyDetail} />} />
        <Route path="/members" element={<ProtectedRoute element={MembersPage} />} />
        <Route path="/vendor-login" element={<VendorLogin />} />
        <Route path="/vendor/homepage" element={<VendorHomepage />} />
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
