import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './ServicePopup.css';

const ServicePopup = ({ service, agencies, onClose }) => {
    const [selectedService, setSelectedService] = useState(false);
    const [preferredVendor, setPreferredVendor] = useState(null);

    useEffect(() => {
        const fetchUserServices = async () => {
            try {
                const response = await axios.get('https://apptvendors.in/api/user-services', {
                    headers: { 'x-auth-token': localStorage.getItem('token') },
                });
                const savedService = response.data.find(userService => userService.serviceId === service.id);
                if (savedService) {
                    setSelectedService(true);
                    setPreferredVendor(String(savedService.agencyId)); // Convert to string
                }
            } catch (err) {
                console.error('Failed to fetch user services:', err);
            }
        };
        fetchUserServices();
    }, [service.id]);

    const handleCheckboxChange = (e) => {
        setSelectedService(e.target.checked);
    };

    const handlePreferredVendorChange = (e) => {
        setPreferredVendor(e.target.value);
    };

    const handleSave = async () => {
        const servicesToSave = [{
            serviceId: service.id,
            preferredVendor: selectedService ? preferredVendor : null // If unchecked, set preferredVendor as null
        }];

        try {
            await axios.post('https://apptvendors.in/api/user-services', {
                services: servicesToSave
            }, {
                headers: { 'x-auth-token': localStorage.getItem('token') },
            });
            alert('Service saved successfully!');
            onClose();
        } catch (err) {
            console.error('Failed to save service:', err);
            alert('Failed to save service.');
        }
    };

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className='but'>
                    <box-icon
                        name='x-circle'
                        class="close-icon"
                        size="lg"
                        onClick={onClose}>
                    </box-icon>
                </div>

                <h2>{service.name}</h2>
                <img src={service.image_url} alt={service.name} />
                <p>{service.description}</p>

                <h3>Agencies offering this service:</h3>
                <ul>
                    {agencies.map((agency) => (
                        <li key={agency.id}>
                            <Link to={`/agencies/${agency.id}`}>
                                <h4>{agency.name}</h4>
                            </Link>
                            <img src={agency.photoUrl} alt={agency.name} className="agency-photo" />
                            <p>{agency.description}</p>
                            <p>Rate: {agency.rate_per_hour}</p>
                            <p>Contact: {agency.contact}</p>
                            <input
                                type="radio"
                                value={agency.id}
                                checked={preferredVendor === String(agency.id)}
                                onChange={handlePreferredVendorChange}
                                style={{display: 'inline-block', marginRight: '5px'}}
                            />
                            <label>Select as Preferred Vendor</label>
                        </li>
                    ))}
                </ul>

                {/* Add checkbox to select service */}
                <div className="service-checkbox">
                    <input
                        type="checkbox"
                        checked={selectedService}
                        onChange={handleCheckboxChange}
                    />
                    <label>Select this service</label>
                </div>

                <button onClick={handleSave}>Save</button>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default ServicePopup;
