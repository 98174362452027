import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './VendorLogin.css';

function VendorLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Implement vendor login logic here
    // For now, let's just simulate a successful login
    if (email && password) {
      // Simulate successful login
      navigate('/vendor/homepage');
    } else {
      setError('Please enter both email and password');
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Vendor Login</h2>
        {error && <p className="error-message">{error}</p>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
        {/* <p className="signup-link">
          Don't have an account? <a href="/vendor/signup">Sign up</a>
        </p> */}
      </form>
    </div>
  );
}

export default VendorLogin;
