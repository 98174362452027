import React, { useState } from 'react';
import axios from 'axios';
import './ForgotPassword.css'; // Import the CSS file

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://apptvendors.in/api/auth/forgot-password', { email });
      alert('Password reset email sent.');
    } catch (error) {
      console.error(error);
      alert('Error sending password reset email.');
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-card">
        <h1 className="forgot-password-title">Forgot Password</h1>
        <form onSubmit={handleSubmit} className="forgot-password-form">
          <input 
            type="email" 
            placeholder="Enter your email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
            className="forgot-password-input"
          />
          <button type="submit" className="forgot-password-button">Send Reset Email</button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
