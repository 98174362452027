
import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Typed from 'typed.js';
import './Home.css';

const Home = () => {
  const [apartments, setApartments] = useState([]);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const typedRef = useRef(null);

  useEffect(() => {
    const fetchApartments = async () => {
      try {
        const response = await axios.get('https://apptvendors.in/api/apartments');
        setApartments(response.data);
      } catch (error) {
        console.error('Failed to fetch apartments', error);
      }
    };

    fetchApartments();

    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  useEffect(() => {
    if (user) {
      const options = {
        strings: [`${user.username}`, `${user.userId}`],
        typeSpeed: 100,
        backSpeed: 100,
        backDelay: 1000,
        loop: true,
        startDelay: 1000,
      };

      typedRef.current = new Typed('.typed-text', options);

      return () => {
        typedRef.current.destroy();
      };
    }
  }, [user]);

  const handleApartmentClick = (apartmentId) => {
    const token = localStorage.getItem('token');
    
    if (token) {
      navigate(`/services?apartmentId=${apartmentId}`);
    } else {
      navigate('/login');
    }
  };

  return (
    <div>
      <Navbar />
      <div className="home">
        {user && (
          <div className="welcome-message">
            <span>Welcome, </span>
            <span className="typed-text"></span> {/* This will animate between username and userId */}
          </div>
        )}
        <div className="header-container">
          <img src="/logo.png" alt="Logo" className="home-logo" />
          <h1>Welcome to Apartment Services</h1>
        </div>
        <p className="intro-text">Select your apartment and Get the List of Different Vendors for various Services</p>
        <div className="apartment-grid">
          {apartments.map(apartment => (
            <div 
              key={apartment.id} 
              className="apartment-card"
              onClick={() => handleApartmentClick(apartment.id)}
            >
              <img src={apartment.image_url} alt={apartment.name} />
              <h2>{apartment.name}</h2>
              <p>{apartment.description}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer class="footer" />
    </div>
  );
};

export default Home;

