// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import ServiceCard from '../Component/ServiceCard';
// import Navbar from '../Component/Navbar';
// import Footer from '../Component/Footer';
// import './ServiceSelection.css';
// import ServiceSelectionPopup from '../Component/ServiceSelectionPopup'; // Import the new popup component
// import { useNavigate } from 'react-router-dom'; // Import useNavigate

// const ServiceSelection = () => {
//   const [services, setServices] = useState([]);
//   const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
//   const navigate = useNavigate(); // Initialize navigate

//   useEffect(() => {
//     const fetchServices = async () => {
//       try {
//         const response = await axios.get('https://apptvendors.in/api/services', {
//           headers: { 'x-auth-token': localStorage.getItem('token') }
//         });
//         setServices(response.data);
//       } catch (err) {
//         console.error(err);
//       }
//     };

//     fetchServices();
//   }, []);

//   const handleShowPopup = () => {
//     setShowPopup(true); // Show the popup
//   };

//   const handleClosePopup = () => {
//     setShowPopup(false); // Close the popup
//   };

//   // Function to navigate to the service users table page
//   const handleViewServiceUsers = () => {
//     navigate('/service-users'); // Navigate to the ServiceUsersTable page
//   };

//   return (
//     <div>
//       <Navbar />
//       <div className="service-selection">
//         {/* Add the button to view Service Users in the top corner */}
        
//         <h1>Available Services</h1>
        
//         {/* Move the "All the services you want to avail" section here */}
//         <div className="services-available">
//           <h2>All the services you want to avail</h2>
//           <button onClick={handleShowPopup}>View & Select Services</button>
//           <button  onClick={handleViewServiceUsers}>
//           View Service Users
//         </button>

//         </div>
        
//         <div className="service-grid">
//           {services.map(service => (
//             <ServiceCard key={service.id} service={service} />
//           ))}
//         </div>
//       </div>

//       {/* Popup Component */}
//       {showPopup && (
//         <ServiceSelectionPopup
//           services={services}
//           onClose={handleClosePopup}
//         />
//       )}

//       <Footer />
//     </div>
//   );
// };

// export default ServiceSelection;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ServiceCard from '../Component/ServiceCard';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';
import './ServiceSelection.css';
import PromoPopup from './PromoPopup'; // Import the PromoPopup component
import ServiceSelectionPopup from '../Component/ServiceSelectionPopup'; // Import the ServiceSelectionPopup component
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ServiceSelection = () => {
  const [services, setServices] = useState([]);
  const [showPromoPopup, setShowPromoPopup] = useState(false); // State for promo popup
  const [showServicePopup, setShowServicePopup] = useState(false); // State for service selection popup
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('https://apptvendors.in/api/services', {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setServices(response.data);

        // Show Promo Popup if not shown in this session
        const promoPopupShown = sessionStorage.getItem('promoPopupShown');
        console.log('iam her ', promoPopupShown);
        
        if (!promoPopupShown) {
          setShowPromoPopup(true); // Show the promo popup if it hasn't been shown yet
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchServices();
  }, []);

  const handleShowServicePopup = () => {
    setShowServicePopup(true); // Manually trigger the service selection popup
  };

  const handleCloseServicePopup = () => {
    setShowServicePopup(false); // Close the service selection popup
  };

  const handleClosePromoPopup = () => {
    setShowPromoPopup(false);
    sessionStorage.setItem('promoPopupShown', 'true'); // Mark that the promo popup has been shown this session
  };

  // Function to navigate to the service users table page
  const handleViewServiceUsers = () => {
    navigate('/service-users'); // Navigate to the ServiceUsersTable page
  };

  return (
    <div>
      <Navbar />
      <div className="service-selection">
        <h1>Available Services</h1>
        <div className="services-available">
          <h2>All the services you want to avail</h2>
          <button onClick={handleShowServicePopup}>View & Select Services</button>
          <button onClick={handleViewServiceUsers}>View Service Users</button>
        </div>
        
        <div className="service-grid">
          {services.map(service => (
            <ServiceCard key={service.id} service={service} />
          ))}
        </div>
      </div>

      {/* Service Selection Popup Component */}
      {showServicePopup && (
        <ServiceSelectionPopup
          services={services} // Pass the service data to the Service Selection popup
          onClose={handleCloseServicePopup}
        />
      )}

      {/* Promo Popup Component */}
      {showPromoPopup && (
        <PromoPopup
          services={services} // Pass the service data to the promo popup
          onClose={handleClosePromoPopup}
        />
      )}

      <Footer />
    </div>
  );
};

export default ServiceSelection;
