// StarRating.js
import React, { useState } from "react";
import "./StarRating.css";

const StarRating = ({ rating, setRating = null, interactive = false }) => {
  const [hover, setHover] = useState(null); // To track the star being hovered over

  return (
    <div className="star-rating">
      {[...Array(5)].map((_, index) => {
        const currentRating = index + 1;

        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={currentRating}
              onClick={() => interactive && setRating && setRating(currentRating)} // Set the rating if interactive
              style={{ display: "none" }} // Hide the radio input
            />
            <span
              className="star"
              style={{
                color: currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9", // Highlight stars
                cursor: interactive ? "pointer" : "default", // Show pointer only if interactive
              }}
              onMouseEnter={() => interactive && setHover(currentRating)} // Set hover rating if interactive
              onMouseLeave={() => interactive && setHover(null)} // Reset hover if interactive
            >
              &#9733;
            </span>
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;
